import { render, staticRenderFns } from "./account-setting.vue?vue&type=template&id=0c2e16da&scoped=true"
import script from "./account-setting.ts?vue&type=script&lang=ts&external"
export * from "./account-setting.ts?vue&type=script&lang=ts&external"
import style0 from "./account-setting.scoped.scss?vue&type=style&index=0&id=0c2e16da&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c2e16da",
  null
  
)

export default component.exports